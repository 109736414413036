dmx.Component('bs4-alert', {

  attributes: {
    show: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'primary',
      enum: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'],
    },

    closable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle () {
      this._toggle();
    },

    show () {
      this._show();
    },

    hide () {
      this._hide();
    },

    setType (style) {
      this._setType(style);
    },

    setTextContent (text) {
      this._setTextContent(text);
    },
  },

  init (node) {
    this._closeButton = jQuery('<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>').on('click.bs.alert', () => {
      this.hide();
    });
  },

  render (node) {
    node.setAttribute('role', 'alert');
    node.classList.add('alert', 'alert-' + this.props.type);
    
    if (this.props.show) {
      node.classList.add('show');
    } else {
      node.style.setProperty('display', 'none');
    }
    
    if (this.props.closable) {
      jQuery(node).append(this._closeButton);
      node.classList.add('alert-dismissible');
    }
    
    this.$parse();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('type')) {
      this._setType(this.props.type);
    }

    if (updatedProps.has('show')) {
      this[this.props.show ? '_show' : '_hide']();
    }
  },

  destroy () {
    jQuery(this.$node).off('.bs.alert');
    jQuery(this._closeButton).off('.bs.alert');
  },

  _toggle () {
    this[this.$node.classList.contains('show') ? '_hide' : '_show']();
  },

  _show () {
    this.$node.style.removeProperty('display');
    this.$node.offsetWidth;
    this.$node.classList.add('show');
  },

  _hide () {
    if (this.$node.classList.contains('fade')) {
      jQuery(this.$node).one('transitionend.bs.alert', () => {
        this.$node.style.setProperty('display', 'none');
      });
    } else {
      this.$node.style.setProperty('display', 'none');
    }
    this.$node.classList.remove('show');
  },

  _setType (type) {
    const types = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].map(type => 'alert-' + type);
    this.$node.classList.remove(...types);
    this.$node.classList.add('alert-' + type);
  },

  _setTextContent (text) {
    this.$node.textContent = text;
    if (this.props.closable) {
      jQuery(this.$node).append(this._closeButton);
    }
  },

});
